<template>
    <div class="toolbar_main">
        <div class="osahan-breadcrumb">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a :href="'/tienda/'+sucursal_seleccionada"><i class="icofont icofont-ui-home"></i> Inicio</a></li>
                            <li class="breadcrumb-item active">Sucursales</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="our-team-main">
            <div class="container" style="background-color: white;">
                <div class="section-header section-header-center text-center">
                    <h3 class="heading-design-center-h3 pt-3">Conoce nuestras sucursales</h3>
                </div>
                <div class="row"></div>
            </div>
        </section>

        <section class="our-team-main" style="padding-top: 0px;">
            <div class="container"> 
                <div class="row" style="background-color: white;padding-top: 20px;padding-bottom: 20px;">
                    <div class="col-3">
                        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist">
                            <a v-for="(val, key) in sucursales" :key="key" :class="['nav-link', (key==0)?'active':'']" :id="'v-pills-'+key+'-tab'" data-toggle="pill" :href="'#v-pills-'+key">
                                {{val.sucursal}}
                            </a>
                        </div>
                    </div>

                    <div class="col-5">
                        <div class="tab-content" id="v-pills-tabContent">
                            <div v-for="(val, key) in sucursales" :key="key" :class="['tab-pane', 'fade', (key==0)?'active show':'']" :id="'v-pills-'+key" role="tabpanel">
                                <h1>{{val.sucursal}}</h1>
                                <span><b>Dirección</b> {{val.direccion}}</span>
                                <br>
                                <span>
                                    <b>Horario atención</b> 
                                    <span v-if="val.horario_atencion"> {{val.horario_atencion}}</span>
                                    <span v-else class="color_claro"><i> Aún no se define el horario de atención</i></span>
                                </span>
                                <br>
                                <span><b>Teléfono atención</b>: {{val.telefonos}}</span>
                                <div class="row">
                                    <div class="col-lg-12" style="padding-top: 20px;">
                                        <div v-if="val.longitud && val.latitud" class="embed-responsive embed-responsive-16by9">
                                            <iframe border='0' class="embed-responsive-item" allowfullscreen width="100%" :src="'https://maps.google.com/maps?q='+val.latitud+','+val.longitud+'&hl=es;z=14&amp;output=embed'"></iframe>
                                        </div>
                                        <div v-else class="mt-4">
                                            <center><i class="fa fa-map-marker" style="font-size:80px;"></i></center>
                                            <h1>No se encontró vista previa del mapa <i class="fa fa-frown-o"></i></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="row">
                            <div class="col-sm-12">
                                <h3 style="font-weight: bold;" class="mb-3">Atención al cliente:</h3>
                                <p class="mb-1 pb-0" style="font-size: 1rem;"><i class="fa fa-envelope-o"></i> {{empresa.email}} </p>
                                <p v-if="empresa.telefono_movil" class="mb-0 pb-0" style="font-size: 1rem;">
                                    <a :href="'https://api.whatsapp.com/send?phone='+empresa.telefono_movil+'&text=%F0%9F%98%80%20Hola%20Quisiera%20hacer%20una%20consulta'" target="_blank">
                                        <i class="fa fa-whatsapp"></i> Escribame por WhatsApp
                                    </a>
                                </p>
                                <p v-else class="mb-0">
                                    <i class="fa fa-whatsapp"></i><i class="color_claro"> Número de WhatsApp no definido</i>
                                </p>
                            </div>
                        </div>

                        <form class="needs-validation mb-3" novalidate="true">
                            <div class="row">
                                <div class="col-sm-12">
                                    <hr>
                                    <h3 style="font-weight: bold;" class="mb-3">Enviar un mensaje:</h3>
                                    <div class="form-group">
                                        <strong class="required">Nombre Completo</strong>
                                        <input class="form-control" placeholder="Ingrese nombre competlo" type="text" required :readonly="readonly" @keypress="isLetter" v-model="formContacto.nombre_completo" ref="nombre_completo">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-8">
                                    <div class="form-group">
                                        <strong class="required">Correo electrónico</strong>
                                        <input class="form-control" placeholder="cliente@gmail.com" type="email" :readonly="readonly" v-model="formContacto.email" ref="email">
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <strong class="required">Telf. Móvil</strong>
                                        <input class="form-control" placeholder="942000001" type="text" :readonly="readonly" maxlength="9" v-model="formContacto.celular" @keypress="isNumberInteger" ref="celular">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <strong class="required">Tu mensaje</strong>
                                        <textarea class="form-control" :readonly="readonly" v-model="formContacto.mensaje" rows="3" ref="mensaje" placeholder="Escriba su mensaje"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 text-center">
                                    <button type="button" class="btn btn-block mt-3 cursor-pointer btn-outline-danger" :disabled="disabled" @click="enviarComentario">
                                        <i class="fa fa-envelope-o"></i> Enviar mensaje
                                    </button>
                                </div>
                            </div>

                            <div class="row" v-if="errors.length">
                                <div class="col-12" >
                                    <ul class="list-group mt-2">
                                        <li v-for="(val, key) in errors" :key="key" class="list-group-item list-group-item-danger p-1">{{val}}</li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';

    export default {
        name: 'sucursales',
        computed:{
            ...mapState(['empresa', 'mi_cuenta', 'sucursales', 'sucursal_seleccionada']),
        },
        created(){
            this.loadObjSucursalSeleccionada();
            this.loadObjPerfilUsuario();
        },
        mounted(){
            document.title = "Sucursales | "+((this.empresa.abreviatura)?this.empresa.abreviatura:"-");
        },
        data(){
            return{
                formContacto:{
                    nombre_completo:"",
                    email:"",
                    celular:"",
                    asunto:"",
                    mensaje:"",
                },
                errors:[],
                disabled: false,
                readonly: false,
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjPerfilUsuario', 'loadObjSucursalSeleccionada']),
            enviarComentario(){
                this.errors = [];

                if(this.formContacto.nombre_completo==""){
					this.$refs.nombre_completo.focus();
                    this.errors.push("Escriba su nombre completo.");
					return;
				}

                if(this.formContacto.email==""){
                    this.$refs.email.focus();
                    this.errors.push("Escriba su correo electronico.");
                    return;
                }else if (!this.validEmail(this.formContacto.email)) {
                    this.$refs.email.focus();
                    this.errors.push('El correo electrónico debe ser válido.');
                    return;
                }

                if(this.formContacto.celular==""){
					this.$refs.celular.focus();
                    this.errors.push("Escriba su numero de celular.");
					return;
				}

                if(this.formContacto.mensaje==""){
					this.$refs.mensaje.focus();
                    this.errors.push("Escriba su mensaje.");
					return;
				}

                var formPost = new FormData();
                formPost.append('nombre_completo', this.formContacto.nombre_completo);
                formPost.append('email', this.formContacto.email);
                formPost.append('celular', this.formContacto.celular);
                formPost.append('asunto', this.formContacto.asunto?this.formContacto.asunto:'HOLA '+this.empresa.abreviatura+', QUIERO CONTACTARME');
                formPost.append('mensaje', this.formContacto.mensaje);

                this.$http.post('tiendaVirtual/contactenos_email', formPost).then((res)=>{
                    this.disabled = true;
                    this.readonly = true;
                    if(res.data.type && res.data.type=="success"){
                        toastr.success("Su mensaje o comentario fue enviado con éxito",'Mensaje');
                        setTimeout(()=>{
                        location.reload();
                        }, 4000);
                        return;
                    }

                    toastr.error("Hubo problemas al enviar le mensaje, por favor verique los datos ingresados o intente en breves minutos",'Advertencia');

                    setTimeout(()=>{
                        location.reload();
                    }, 4500);
                }).catch(e => {
                    if(e && e.response && e.response.status==422){ //Errores de Validacion
                        const mapErrors ={};
                        for (let field of Object.keys(e.response.data.errors)) {
                            mapErrors[field]=e.response.data.errors[field][0];

                            toastr.error(mapErrors[field],'Advertencia');
                        }
                    }
                }).finally(()=>{
                    this.setLoading(false);
                });
            },
        },
    }
</script>

<style scoped>
    .nav-link{
        border: 1px solid #80808054;
        margin: 1px;
        font-weight: 600;
    }
</style>